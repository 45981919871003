<template>
  <h3>Clients</h3>
  <template v-if="loaded && clientList.length">
    <div class="loginContent" v-if="clientList.length">
      <ul class="org-list org-list-page">
        <li
          v-for="client in clientList"
          :key="client.id"
          v-on:click.prevent="
            saveClient(
              client.name,
              client.id,
              client.logo,
              client.user_client_role.role_details
            )
          "
        >
          <div class="pointer">
            <img
              :src="
                client.logo
                  ? imgURL + client.logo
                  : '/assets/images/logoOneCore.png'
              "
              title="Client"
            />
            {{ client.name }}
          </div>
        </li>
      </ul>
    </div>
  </template>
  <template v-else-if="loaded && !clientList.length">
    <div class="no-items">
      <p class="text-danger">No clients Available</p>
      <span class="logout" @click="handleLogout">Logout</span>
    </div>
  </template>
  <template v-else>
    <div class="base-empty">
      <div class="loading">
        <span class="loader"></span>
      </div>
    </div>
  </template>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import { mapActions, mapMutations } from "vuex";
import { LOGOUT_USER } from "@/store/storeconstants";
export default {
  title() {
    return `Remote Support | ${this.title}`;
  },
  data() {
    return {
      title: "Clients",
      clientList: [],
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
    };
  },
  methods: {
    ...mapActions("auth", {
      logout: LOGOUT_USER,
    }),
    ...mapMutations({
      setRole: "account/setClientRole",
    }),
    handleLogout() {
      this.logout();
      this.emitter.emit("logout");
      this.$router.replace("/");
    },
    async fetchClients() {
      this.loaded = false;
      const org = JSON.parse(localStorage.getItem("organization"));
      const response = await axiosInstance.get(
        `/new-org-client-list-all?org_id=${org.id}&user_client=1`
      );
      if (response) {
        this.loaded = true;
        this.clientList = response.data.data;
      } else {
        this.loaded = true;
        this.emitter.emit("alert", "Something went wrong");
      }
    },
    saveClient(name, id, logo, role) {
      const currentUser = JSON.parse(localStorage.getItem("U_P"));
      currentUser.role = role;
      localStorage.setItem("U_P", JSON.stringify(currentUser));
      let clientData = {
        id: id,
        name: name,
        logo: logo,
      };
      localStorage.setItem("client", JSON.stringify(clientData));
      this.setRole(role);
      this.$router.push("/data-structure");
    },
  },
  mounted() {
    this.fetchClients();
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.base-empty {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .loading {
    height: 100%;
  }
}

.no-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 20px;
  .logout {
    cursor: pointer;
    font-weight: bold;
    background: #dc3545;
    color: white;
    padding: 5px 15px;
  }
}
</style>